import React from 'react'
import Layout from "../components/layout"
import { Background, Parallax } from "react-parallax"
import { Grid, Row, Col} from 'react-bootstrap'
import SEO from "../components/seo"
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { graphql, Link } from "gatsby"
import { LazyLoadImage } from "react-lazy-load-image-component"
import TitleHeader from "../components/title-header"


const CareersPage = ({ data }) =>
    <Layout>
    <SEO title="Careers | Korecki Real Estate Services" description="Our Strata Property Management and Residential Property Management portfolio is rapidly growing, and we are always on the lookout for well qualified, ambitious team players" keywords="korecki,Real Estate,Strata Management services, Job, Career, Careers, Korecki Team"/>
      <TitleHeader title={"Careers"} />
      <Grid fluid className="half-section-container feature-section">
        <Col md={6} className="half-section">
          <div className="parallax-half-section">
            <LazyLoadImage
              alt="banner"
              height={350}
              style={{ objectFit: "cover" }}
              width="100%"
              src={data.contentfulCareersPage.image.file.url}
            />
          </div>
        </Col>
        <Col md={6} className="half-section blocktext" style={{ backgroundColor: 'rgba(250,250,250,0)' }}>
          <section>
            <div className="col-half-section">
            <Row className="heading">
              <Col md={12}>
                <h2 className="no-p">{data.contentfulCareersPage.title}</h2>
              </Col>
            </Row>
          <Row>
            <Col className="custom-text-color-children" md={12}>
              {documentToReactComponents(data.contentfulCareersPage.bodyText.json)}
            </Col>
          </Row>
            </div>
          </section>
        </Col>
      </Grid>
  </Layout>

export const query = graphql`
  {
      contentfulCareersPage(title: {eq: "Join the Korecki Team"}) {
      title
      bodyText {
        json
      }
      image {
      	file {
      		url
      	}
      }
    }
  }`

export default CareersPage
